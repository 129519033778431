import React from "react"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Logo from "./logo"

const Header = () => {
  return <header>
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <Logo/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto align-middle">
          <Nav.Link href="/diakoknak">Diákoknak</Nav.Link>
          <Nav.Link href="/tanaroknak">Tanároknak</Nav.Link>
          <Nav.Link href="/szuloknek">Szülőknek</Nav.Link>
          <Nav.Link href="/category/tudasbazis">Tudásbázis</Nav.Link>
          <Nav.Link href="/linkek">Linkek</Nav.Link>
          <Nav.Link href="/kapcsolat">Kapcsolat</Nav.Link>
          <Nav.Link href="http://video.sgyak2014b.hu">Videók</Nav.Link>
        </Nav>
        {/*<Form inline>*/}
        {/*  <FormControl type="text" placeholder="Keresés" className="mr-sm-2"/>*/}
        {/*  <Button variant="outline-success">Keresés</Button>*/}
        {/*</Form>*/}
      </Navbar.Collapse>
    </Navbar>
  </header>
}

export default Header
