import React from "react"
import "../scss/bootstrap-custom.scss"
import Container from "react-bootstrap/Container"
import Header from "./header"
import Logo from "./logo"

const Layout = ({ location, title, children }) => {
  return (
    <Container fluid={true}>
      <header><Header/></header>
      <main>
        <Container className="mt-5 mb-5">
          {children}
        </Container>
      </main>
      <footer>
        <Container className="text-right" fluid={true}>
          <hr/>
          <Logo/>
          <p>SGYAK2014B honlapja.</p>
        </Container>
      </footer>
    </Container>
  )
}

export default Layout
